<template>
  <div style="height: 100vh; width: 100vw;">
    <div @click="$router.push('/')" style="padding: 20px 0 0 20px;font-weight: bolder; color: white;font-size: 20px; cursor: pointer;">
      Mortgage Envy
    </div>
    <div
      v-if="loaded"
      class="d-flex justify-content-center align-items-center full-height"
    >
      <div
        class="col-5 align-self-center"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item
            :title="questions.questionOne.question"
          >
            <b-row>
              <div class="col-6">
                <hwa-input
                  v-model="questions.questionOne.userProvidedAns.first_name"
                  placeholder="First Name"
                />
              </div>
              <div class="col-6">
                <hwa-input
                  v-model="questions.questionOne.userProvidedAns.last_name"
                  placeholder="Last Name"
                />
              </div>
              <div class="col-6">
                <hwa-input
                  v-model="questions.questionOne.userProvidedAns.email"
                  placeholder="Email"
                />
              </div>
              <div class="col-6">
                <hwa-input
                  v-model="questions.questionOne.userProvidedAns.phone"
                  placeholder="Phone"
                />
              </div>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <div class="mt-2" />

        <div class="d-flex">
          <hwa-button
            icon="ArrowLeftCircleIcon"
            label="PREVIOUS"
            @onClick="$router.back()"
          />
          <div class="ml-1" />
          <hwa-button
            :disabled="!valid"
            label="NEXT"
            :loading="nextLoading"
            @onClick="onNext()"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center full-height"
    >
      <grid-loader
        :loading="true"
        color="white"
      />

    </div>
  </div>
</template>

<script>
import { BRow } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default {
  name: 'StartPurchase2',
  components: {
    HwaButton,
    HwaInput,
    AppCollapse,
    AppCollapseItem,
    BRow,
    GridLoader,
  },
  data() {
    return {
      accept: false,
      loaded: false,
      selectedQ3Answer: null,
      selectedQ1Answer: null,
      nextLoading: false,
      questions: {
        questionOne: {
          id: null,
          question: '',
          answers: [],
          userProvidedAns: {
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
          },
        },
      },
    }
  },
  computed: {
    valid() {
      return !!this.questions.questionOne.userProvidedAns.first_name
              && !!this.questions.questionOne.userProvidedAns.last_name
              && !!this.questions.questionOne.userProvidedAns.email
              && !!this.questions.questionOne.userProvidedAns.phone
    },
  },
  mounted() {
    this.getStepQuestions()
  },
  methods: {
    onNext() {
      const id = localStorage.getItem('anonymous')
      this.nextLoading = true
      const completed = this.$store.getters['auth/isLoggedIn']
      this.$http.post(`api/questions/purchase/4/${id}?application_id=${this.$store.state.auth.applicationId}&completed=${completed}`, this.questions).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: 'All set, complete.',
            variant: 'success',
          },
        })
        this.nextLoading = false
        if (completed) {
          this.$router.push('/')
        } else {
          this.$router.push({ name: 'complete', params: { type: 'purchase' } })
        }
      }).catch(err => {
        this.nextLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'CheckCircleIcon',
            text: 'Something went wrong, try again.',
            variant: 'danger',
          },
        })
      })
    },
    getStepQuestions() {
      const id = localStorage.getItem('anonymous')
      this.$http.get(`api/questions/purchase/4/${id}?update=${this.$store.state.auth.updateRequest}&application_id=${this.$store.state.auth.applicationId}`).then(res => {
        this.questions = res.data
        this.loaded = true
      })
    },
  },
}
</script>

<style scoped>

</style>
